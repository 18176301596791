






















import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import servicePackageStoreModule from "@/store/modules/servicePackage";

const NAMESPACE = "CLIENT_PACKAGES_LIST";

const { mapActions: servicePackageActions, mapGetters: servicePackageGetters } =
  createNamespacedHelpers(NAMESPACE);

export default Vue.extend<any, any, any, any>({
  name: "ClientServicePackagesList",
  props: {
    clientId: {
      type: String,
      required: false,
    },
    businessId: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Package Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Price",
        align: "start",
        sortable: true,
        value: "price",
      },
      {
        text: "Maximum Usage Count",
        align: "start",
        sortable: true,
        value: "maxUsageCount",
      },
      {
        text: "Usage Count",
        align: "start",
        sortable: true,
        value: "usageCount",
      },
      // { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
  }),
  created() {
    this.fetchPackages();

    if (!this.clientId) {
      this.headers.splice(0, 0, {
        text: "Client",
        align: "start",
        sortable: true,
        value: "client.fullName",
      });
    }
  },
  computed: {
    ...servicePackageGetters(["clientServicePackagePage"]),
  },
  watch: {
    options: {
      handler() {
        this.fetchPackages();
      },
      deep: true,
    },
  },
  methods: {
    ...servicePackageActions(["fetchClientServicePackageList"]),
    async fetchPackages() {
      const page = this.options.page || 1;
      const limit = this.options.limit || 10;
      let params = `?page=${page}&limit=${limit}`;
      if (this.clientId) {
        params += `&clientId=${this.clientId}`;
      }
      if (this.businessId) {
        params += `&businessId=${this.businessId}`;
      }
      this.fetchClientServicePackageList(params);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule(NAMESPACE)) {
      this.$store.registerModule(NAMESPACE, servicePackageStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule(NAMESPACE);
  },
});
